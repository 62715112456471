<template>
  <a-auto-complete
    :value="value"
    option-label-prop="value"
    :filter-option="filterOption"
    :allowClear="true"
    @search="onSearch"
    @select="onSelect"
  >
    <template slot="dataSource">
      <a-select-option v-for="item in dataSource" :key="item.id" :value="item.name" :item="item">
        {{ item.name }}
      </a-select-option>
    </template>
  </a-auto-complete>
</template>

<script>
import { clientsOption } from "@/api/option";
import { pinyin } from "pinyin-pro";

export default {
  props: ["value"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      dataSource: [],
    };
  },
  methods: {
    filterOption(input, option) {
      const optionLabel = option.componentOptions.children[0].text.toLowerCase();
      const optionChars = pinyin(optionLabel, { pattern: "first", type: "array" });
      const optionPinyin = optionChars.join("").toLowerCase().replace(/\s+/g, "");
      const inputText = input.toLowerCase();

      return optionLabel.indexOf(inputText) !== -1 || optionPinyin.indexOf(inputText) !== -1;
    },
    onSearch(value) {
      this.$emit("change", value);
    },
    onSelect(value, option) {
      this.$emit("change", value);
      this.$emit("select", option.data.attrs.item);
    },
  },
  mounted() {
    clientsOption({ page_size: 999999, is_active: true }).then((data) => {
      this.dataSource = data.results;
    });
  },
};
</script>
